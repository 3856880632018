import axios from 'axios'

// env variables
import getConfig from 'next/config'
import { CURRENT_NODE_ENV, GERS_X_API_KEY, GERS_X_API_VALUE } from '../../settings/variables'
import appendQueryString from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/appendQueryString'
import { GERS_SERVICE } from '../../settings/variables'
const { publicRuntimeConfig } = getConfig()
export const CMS_API = publicRuntimeConfig.CMS_API || 'http://localhost:1337'
export const CACHE_ENDPOINT = publicRuntimeConfig.CACHE_ENDPOINT || ''

let enableResponseTimeTracing: boolean = false
if (CURRENT_NODE_ENV) {
  const lower: string = CURRENT_NODE_ENV.toLowerCase()
  if (lower === 'local' || 'development') {
    enableResponseTimeTracing = false
  }
}

const getQueryStringsAsObject = (search: string) => {
  try {
    return JSON.parse(
      '{"' +
        decodeURI(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    )
  } catch (e) {
    return {}
  }
}

const removeQuery = (name: string, targetUrl: string) => {
  return targetUrl
    .split('?')
    .map((url, i) =>
      !i
        ? url
        : url
            .split('&')
            .filter(p => !p.startsWith(name + '='))
            .join('&'),
    )
    .join('?')
}

const getDefaultCmsTransport = (cache?: boolean, useGers?: boolean | string, useApiKey?: boolean) => {
  const hasCache: boolean = CACHE_ENDPOINT !== '' && cache !== undefined && cache === true
  const useGersEndpoint: boolean = useGers === true || useGers === 'true'
  const targetEndpoint = hasCache ? CACHE_ENDPOINT : useGersEndpoint ? GERS_SERVICE : CMS_API
  const apiAxiosInstance = axios.create({ baseURL: targetEndpoint })
  /**
   * Gets called before each request
   */
  apiAxiosInstance.interceptors.request.use(
    config => {
      // tmp so that I can get proper response times from the page (server side)
      if (enableResponseTimeTracing) {
        const tmpCast: any = config as any
        tmpCast.metadata = { startTime: new Date() }
      }
      if (useApiKey) {
        config.headers[GERS_X_API_KEY] = GERS_X_API_VALUE
      }
      if (config.headers.cacheKey) {
        if (hasCache && config.url) {
          config.url = appendQueryString(config.url, { cacheKey: config.headers.cacheKey })
        }
        delete config.headers.cacheKey
      }
      // we don't want cacheKey present in url if going directly to strapi
      if (!hasCache && config.url) {
        const queryAsObject: { [x: string]: any } = getQueryStringsAsObject(config.url)
        if (queryAsObject.cacheKey) {
          config.url = removeQuery('cacheKey', config.url)
        }
      }
      return config
    },
    error => {
      return Promise.reject(error)
    },
  )

  apiAxiosInstance.interceptors.response.use(
    config => config,
    error => {
      console.log(error)
      return Promise.reject(error)
    },
  )

  apiAxiosInstance.interceptors.response.use(
    response => {
      if (enableResponseTimeTracing) {
        const tmpCast: any = response.config as any
        const {
          metadata: { startTime },
        } = tmpCast
        const endTime: any = new Date()
        const duration = endTime - startTime
        const { url, data } = response.config
        // tslint:disable-next-line:no-console
        console.log(`REQUEST: ${url} ------------------> ${duration}ms`)
        if (data) {
          // tslint:disable-next-line:no-console
          console.log(`-------------REQUEST BODY: ${data}`)
        }
      }
      return response
    },
    error => {
      if (error.response) {
        const url: string = error?.response?.config?.url
        const body: string = error?.response?.config?.data
        const message: string = error?.response?.data
        if (error?.response?.status !== 404) {
          // Opinionated: do not record an exception in Sentry for 404
          console.log(
            JSON.stringify({
              url,
              body,
              message,
            }),
          )
        }
      } else {
        console.log(error)
      }

      return Promise.reject(error)
    },
  )
  return apiAxiosInstance
}

export default getDefaultCmsTransport
